@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-Regular.ttf);
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: 'OpenSans', serif;
}


body {display:flex; flex-direction:column;}