.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 0 5px;
}

.NavItem a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavItem a:hover, .NavItem a:active, .NavItem a.active {
    color: lightgrey;
    text-decoration: none;
}

@media (min-width: 993px) {
    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    .NavItem a {
        color: lightgrey;
        height: 100%;
        padding: 6px 10px;
        border-bottom: 4px solid transparent;
    }
    .NavItem a:hover, .NavItem a:active, .NavItem a.active {
        color: lightgrey;
        background-color: rgb(109, 109, 116);
        border-radius: 20px;
        /* border-bottom: 4px solid #40A4C8; */
    }
}

@media (min-width: 993px)  and (max-width: 1419px) {
    .NavItem a{
        font-size: 8pt;
    }
}