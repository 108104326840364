.Skill {
    padding: 0 15px 0 5px;
    margin: 0 10px 10px 0;
    text-align: center;
    float: left;
    width: 120px;
    height: 170px;
}

.Chart {
    margin: 0 auto;
}