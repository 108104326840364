.EducationDetails{
    color: lightgrey;
}

.EducationDetails hr{
    border-color: rgb(179, 179, 179);
}

.DetailsBox{
    padding: 10px;
    text-align: center;
    background-color: rgb(117, 117, 117);
   
    margin: 5px;
    color: lightgrey;
    float: left;
}

.Content{
    background-color: rgb(41, 41, 41, 0.8);
    border: 4px solid rgb(41, 41, 41) ;
    padding: 15px;
    margin-top: 15px;
}

@media (min-width: 992px){
    .Line{
        display: none;
    }
}