.Languages {
    color: lightgrey;
    animation: anim 0.5s ease-out forwards;
}

.Languages h1 {
    margin-bottom: 25px;
}

@keyframes anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}