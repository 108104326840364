.Toolbar{
    height: 40px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgb(46, 55, 61);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 90;
    font-size: 12pt;
    /* border-bottom: 2px solid lightgrey; */
    border-radius: 20px;
    margin-bottom: 15px;
}

.Toolbar nav{
    height: 100%;

}

@media (max-width: 992px) {
    .DesktopOnly {
        display: none;
    }
}