.Tag{
    padding: 8px;
    background-color: rgb(102, 110, 110); 
    text-align: center;
    float: left;
    margin: 10px 10px 10px 0;
    font-size: 9pt;
    border-radius: 5px;
}

.ProjectDetails{
    animation: anim 0.4s ease-in forwards;
}

@keyframes anim{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}