.Projects{
    animation: anim 0.5s ease-out forwards;
    
    }
    
    @keyframes anim{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 0.3;
        }
        75%{
            opacity: 0.7;
        }
        100%{
            opacity: 1;
        }
    }
    
.Project{
    background-color: rgb(41, 41, 41, 0.8);
    border: 4px solid rgb(41, 41, 41) ;
    padding: 15px;
    animation: animP 0.4s ease-in forwards;
    min-height: 500px;
}

@keyframes animP{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@media (max-width: 992px){
    .Project{
        margin-top: 15px;
    }
}