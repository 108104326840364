.SideDrawer {
    position: fixed;
    width: 250px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgb(46, 55, 61, 0.7);
    padding: 42px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.SideDrawer a {
    color: lightgrey;
}

.SideDrawer a:active {
    font-weight: bold;
}

@media (min-width: 993px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}