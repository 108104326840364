.Skills {
    animation: anim 0.5s ease-out forwards;
}

@keyframes anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.SkillBox {
    width: 100%;
    background-color: rgb(43, 43, 43);
    padding: 10px;
    overflow: auto;
}