.ContactMe {
    animation: anim 0.5s ease-out forwards;
}

@keyframes anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.Content{
    background-color: rgb(41, 41, 41, 0.8);
    border: 4px solid rgb(41, 41, 41) ;
    padding: 20px;
    margin-top: 15px;
    border-radius: 10px;
    overflow: auto;
}

.Content input,
.Content textarea{
    background-color: rgb(56, 56, 56);
    color: lightgrey;
    border-color: transparent;
    border-radius: 10px;

}

.Content input:focus,
.Content textarea:focus{
    background-color: rgb(68, 68, 68);
    color: lightgrey;
    border-color: transparent;
}

.Content button{
    float: right;
}