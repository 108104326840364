.Course{
    margin-bottom: 20px;
}

.CourseBox{
    width: 100%;
    background-color: rgb(43, 43, 43);
    padding: 10px 0;
}

.CourseBox ul li{
    margin-bottom: 10px;
}
