body {
    background-color: #262e33;
}

.Maintenance{
    text-align: center;
}



.Maintenance h1 {
    font-size: 400%;
    color: lightgrey;
}

.Maintenance div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}