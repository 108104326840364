// Here you can add other styles


html, body{
    font-size: 10pt;
}

.main{
    margin-top: 25px;
}

.form-control{
    font-size: 10pt;
}