.AboutMe{
    padding: 15px;
    color: lightgrey;
    animation: anim 0.5s ease-out forwards;
}

.AboutMe hr{
    border-color: lightgrey;
}

.AboutMe p{
    font-size: 12pt;
    text-align: justify;
}

@keyframes anim{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.3;
    }
    75%{
        opacity: 0.7;
    }
    100%{
        opacity: 1;
    }
}

.AboutMe img{
    width: 300px;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: robotAnim 0.5s ease-out forwards;
}

@keyframes robotAnim{
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
    }
}

 @media (max-width: 600px){
    .AboutMe img{
        display: none;
    }
 }