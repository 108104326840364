.Auth{
    margin: 20% auto;
    width: 90%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    animation: auth 0.4s ease-out forwards;
    border-radius: 10px;
}

.Input{
    display: block;
}

@media (min-width:600px){
    .Auth{
        width: 600px;
    }
}

@keyframes auth{
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    50%{
        opacity: 0.5;
        transform: translateY(50%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}