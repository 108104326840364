
.ImgHolder{
    height: 220px;
    width: 220px;
    margin: 25px auto;
    border-radius: 100%;
    border: 3px solid rgb(92, 92, 92);
    background-color: rgb(201, 198, 197);
    animation: anim 0.6s ease-in-out infinite;
}

.InfoBox{
    background-color: rgb(201, 198, 197);
    padding: 12px 12px 5px 12px;
    border: 3px solid rgb(110, 110, 110);
    box-shadow: 0 2px 3px rgb(110, 110, 110);
    animation: anim 0.6s ease-in-out infinite;
}

@keyframes anim{
    0%{
        opacity: 0.8;
    }
    50%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.8;
    }
}