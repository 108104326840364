.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    color: rgb(100, 100, 100);
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px rgb(175, 175, 175);
    padding: 16px;
    left: 15%;
    top: 25%;
    box-sizing: border-box;
    transition: all 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    border-radius: 20px;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}