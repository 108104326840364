.LanguageChart {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.LanguageBox {
    width: 100%;
    background-color: rgb(43, 43, 43);
    padding: 10px 0;
}

/* .LanguageChart h2{
    border-bottom: 1px solid white;
    width: 40%;
} */

.Documents{
    padding: 15px;
}

.Documents hr{
    border-color: lightgrey;
}

@media (min-width: 992px){
    .Documents hr{
        display: none;
    }
}