.Education {
    animation: anim 0.5s ease-out forwards;
}

@keyframes anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.Timeline{
    background-color: rgb(41, 41, 41, 0.8);
    border: 4px solid rgb(41, 41, 41) ;
    padding: 15px 0;
    margin-top: 15px;
}