@font-face {
    font-family: OpenSans;
    src: url(../../../fonts/OpenSans-Regular.ttf);
  }

  @font-face {
    font-family: OpenSans;
    src: url(../../../fonts/OpenSans-Italic.ttf);
  }


.MainInfo{
    color: lightgrey;
    font-family: OpenSans;
    animation: mainInfoAnim 0.4s ease-out forwards;
}
@keyframes mainInfoAnim {
    0%{
        opacity: 0;
        /* transform: rotate(25deg); */
    }
    20%{
        opacity: 0.2;
        /* transform: rotate(20deg); */
    }
    40%{
        opacity: 0.4;
        /* transform: rotate(15deg); */
    }
    60%{
        opacity: 0.6;
        /* transform: rotate(10deg); */
    }
    80%{
        opacity: 0.9;
        /* transform: rotate(5deg); */
    }
    100%{
        opacity: 1;
        /* transform: rotate(0); */
    }
    
}


.MainInfoContent{
    animation: mainInfoContentAnim 0.4s ease-in-out forwards;
}

@keyframes mainInfoContentAnim {
    0%{
        opacity: 0;
        /* transform: translateY(50%); */
    }
    20%{
        opacity: 0.2;
        /* transform: translateY(40%); */
    }
    40%{
        opacity: 0.4;
        /* transform: translateY(30%); */
    }
    60%{
        opacity: 0.6;
        /* transform: translateY(20%); */
    }
    80%{
        opacity: 0.9;
        /* transform: translateY(10%); */
    }
    100%{
        opacity: 1;
        /* transform: translateY(0); */
    }
}

.Spinner{
    margin: 160px auto;
}

